module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Morova - Savitaipaleen Nuorison Tuki ry","short_name":"Morova","description":"Yhdistys edistää nuorten ja pitkäaikaistyöttömien toimintamahdollisuuksia, työllistymistä ja elämänhallintaa.","start_url":"/","display":"standalone","icon":"src/images/morova_logo.svg","lang":"fi","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a01a839c0f99c675cc07f588871b09c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
