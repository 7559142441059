exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-asunnot-js": () => import("./../../../src/pages/asunnot.js" /* webpackChunkName: "component---src-pages-asunnot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myymala-js": () => import("./../../../src/pages/myymälä.js" /* webpackChunkName: "component---src-pages-myymala-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../../../src/pages/ota-yhteyttä.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-tietoa-meista-js": () => import("./../../../src/pages/tietoa-meistä.js" /* webpackChunkName: "component---src-pages-tietoa-meista-js" */),
  "component---src-pages-tyopaja-js": () => import("./../../../src/pages/työpaja.js" /* webpackChunkName: "component---src-pages-tyopaja-js" */)
}

